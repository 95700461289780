exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gift-js": () => import("./../../../src/pages/gift.js" /* webpackChunkName: "component---src-pages-gift-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-rsvp-js": () => import("./../../../src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-spotify-js": () => import("./../../../src/pages/spotify.js" /* webpackChunkName: "component---src-pages-spotify-js" */),
  "component---src-pages-wedshoots-js": () => import("./../../../src/pages/wedshoots.js" /* webpackChunkName: "component---src-pages-wedshoots-js" */)
}

