import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Helmet from 'react-helmet'

const PageWrapper = ({ children, props }) => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet
        defaultTitle={t('metadata.websiteTitle')}
        titleTemplate={t('metadata.websiteTitle') + ' | %s'}
      />
      {children}
    </>
  )
}

export default PageWrapper
